import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import classnames from 'classnames';
import styles from './character-counter-text-field.module.css';

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    resize: none;
    padding-right: 25px;
  }
  @media only screen and (max-width: 899px) {
    & .MuiInputLabel-root {
      white-space: pre-wrap;
    }
    & .MuiInputLabel-shrink {
      white-space: nowrap;
    }
  }
`;

export default function CharacterCounterTextField({
  value = '',
  maxLength = 100,
  error,
  helperText,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  ...props
}) {
  const length = value.length;
  const totalSymbols = `${length}/${maxLength}`;

  return (
    <StyledTextField
        value={value}
        error={error}
        helperText={
        <div className={styles.helperTextWrapper}>
            <div>{helperText}</div>
            {length > 0 && (
            <div className={classnames(styles.symbols, error && styles.error)}>
                {totalSymbols}
            </div>
            )}
        </div>
        }
        inputProps={{ maxLength }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}
    />
  );
}