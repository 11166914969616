import { useState, useCallback, memo, useMemo } from "react";
import { Box, Button, List, ListItem, ListItemIcon, ListItemButton, ListItemText, TextField, Typography, Skeleton } from "@mui/material";
import AttachmentLinks from "components/showcase/AttachmentsLinks";
import FilePrice from "./FilePrice";
import { UpgradeFilesTemplate } from 'components/security/AccessChecker/components/Templates';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FilesTraining from "pages/protected/tools/Training/components/FilesTraining";

const SearchList = ({ files, fileSources, handleSelectItem, handleDeleteFileSources, modelId, prices, smallScreen, isFilesAllowed, isLoading }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const desktopStyling = {
    borderRadius: '4px',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, ' +
      'rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, ' +
      'rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
  };
  const filteredItems = useMemo(() =>
    files.filter(item => item.relate_data?.original_name.toLowerCase().includes(searchTerm)),
    [files, searchTerm]
  );

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value.toLowerCase());
  }, []);

  return (
    <List sx={smallScreen ? {} : desktopStyling}>
      <ListItem 
        secondaryAction={<Typography sx={{
          padding: "4px 10px",
          backgroundColor: "#eee",
          borderRadius: "14px",
          opacity: 0.8,
          position: "relative",
          right: "-6px",
        }} variant="describe_text" >/</Typography>}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          size="small"
          // margin="normal"
          onChange={handleSearchChange}
        />
      </ListItem>
      <Box sx={{ maxHeight: "225px", overflowY: "scroll" }}>
        {
          isLoading ? 
            [...Array(4)].map((_, i) => <Skeleton key={i} variant="text" animation="wave" sx={{ margin: '0 10px' }} />)
            : filteredItems.length == 0 ?
            <ListItem component="a" href="/portal/training/files">
              <ListItemText primary={<Typography variant="describe_text" >No options found</Typography>} />
              <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                <UploadFileIcon />
              </ListItemIcon>
            </ListItem>
            :
            filteredItems.map((item) => {
              const fileId = item.file_id;
              return (
                <ListItemButton
                  key={item.id}
                  selected={fileSources.some(selectedItem => selectedItem.id === fileId)}
                  onClick={() => handleSelectItem(item)}>
                  <ListItemText
                    primaryTypographyProps={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      marginRight: '70px',
                      display: 'block',
                      whiteSpace: 'nowrap', 
                    }}
                    primary={item.relate_data.original_name}
                    secondary={item.relate_data.file_type}
                    sx={{ width: '100%' }} />
                  <FilePrice fileId={fileId} modelId={modelId} cachedPrice={prices[fileId]} />
                </ListItemButton>
              )
            })
        }
      </Box>
      {fileSources.length == 0 ? (
          <ListItem> 
            <ListItemText primary={<Typography variant="describe_text" >0 files chosen</Typography>} />
          </ListItem>
        ) : (
          <ListItem>
            <AttachmentLinks content={fileSources} onDelete={handleDeleteFileSources} />
          </ListItem>
        )
      }
      {/*{!isFilesAllowed && (*/}
      {/*  <UpgradeFilesTemplate/>*/}
      {/*)}*/}
    </List>
  )
};

export default memo(SearchList, (prevProps, nextProps) => {
  return prevProps.modelId === nextProps.modelId && prevProps.fileSources?.length === nextProps.fileSources?.length;
});