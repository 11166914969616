import { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import { Grid, TextField, TextareaAutosize, CircularProgress, InputAdornment, Box, Typography, Button, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import GeneralTooltip from 'components/tooltip/GeneralTooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import useDynamicRefs from 'hooks/useDynamicRefs';
import SaveDataButton from "components/buttons/SaveDataButton";
import FileInput from 'components/inputs/FileInput';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import CharacterCounterTextField from 'components/inputs/text/CharacterCounterTextField';

import styles from './createModelForm.module.css';

const CustomTextField = styled(TextField)`
    .MuiOutlinedInput-input {
      resize: none;
      padding-right: 25px;
    }
    @media only screen and (max-width: 899px) {
      & .MuiInputLabel-root {
        white-space: pre-wrap;
      }
      & .MuiInputLabel-shrink {
        white-space: nowrap;
      }
    }
`;

const CreateModelForm = ({ modelDataIsChanged, isDataSending = false, hintsData, deleteHintHandler, addHintHandler, modelState, setModelState, handleInputChange, file, setFileHandler }) => {
  const filesRef = useRef(null);
  const dispatch = useDispatch();
  const {id: modelId} = useParams();
  // const initialAnimationValue = {name: '', role: '', goals: '', mini_description: '', rules: '', company: '', website: '' };
  const initialInputsState = { name: false, role: false, goals: false, rules: false, company: false, website: false };
  // const [animationValue, setAnimationValue] = useState(initialAnimationValue);
  const [showErrorAnimation, setShowErrorAnimation] = useState(initialInputsState);
  const [isTyping, setIsTyping] = useState(initialInputsState);
  const LARGE_FIELD_LIMIT = 5000;
  const BIG_FIELD_LIMIT = 1000;
  const SMALL_FIELD_LIMIT = 50;
  const formFieldsInitial = [
    {name: "name", label: "AI Model Name", required: true, type: "text", gridProps: {item: true, xs: 12, md: 6},
    tooltip:`The AI model name serves as a distinctive label that represents the essence and purpose of your intelligent system. Whether you're developing a chatbot, image recognition software, or a predictive analytics tool, the AI model name is your opportunity to make a lasting impression.`,
    link:'https://support.younet.ai/article/28-How-should-I-name-AI-model', charactersLimit: SMALL_FIELD_LIMIT},
    {name: "role", label: "Role", required: true, type: "text", gridProps: {item: true, xs: 12, md: 6}
    ,tooltip:"This is where you define the role that your AI system will play. The AI role helps to establish the purpose and function of your intelligent solution, guiding its interactions and responsibilities within your ecosystem.",
    link:'https://support.younet.ai/article/29-How-do-I-specify-the-Role-of-AI-model', charactersLimit: SMALL_FIELD_LIMIT},
    {name: "goals", label: "Purpose", type: "text", required: true,gridProps: {item: true, xs: 12}
    ,tooltip:"This is where you narrow down the purpose of the AI model you create. ",
    link:'https://support.younet.ai/article/33-What-is-the-purpose-of-AI-model', charactersLimit: LARGE_FIELD_LIMIT},
    {name: "mini_description", label: "Short Description", required: true, type: "text", gridProps: {item: true, xs: 12}
    ,tooltip:"Write a short description for your model that reflects its purpose or content.",
    link:'https://support.younet.ai/article/29-How-do-I-specify-the-Role-of-AI-model', charactersLimit: LARGE_FIELD_LIMIT},
    {name: "rules", label: "Rules and limitations", type: "text", required: false,gridProps: {item: true, xs: 12}
    ,tooltip:"This is where you define the rules and limitations of your AI model. When it comes to the settings of an AI model, there are a few key aspects to consider and some of them are Data limitations, Contextual understanding, Ethical considerations, Creative thinking, etc…",
    link:'https://support.younet.ai/article/32-What-are-the-rules-and-limitations-of-the-model', charactersLimit: BIG_FIELD_LIMIT},
    {name:'company',label:"Company",type:"text",gridProps: {item: true, xs: 12}
    ,tooltip:'The "Company" field in the creation of an AI model is an optional field where you can enter the name of your company. This field allows you to associate your AI model with a specific company if applicable. It can be useful for organizational purposes or if you are creating the AI model on behalf of a particular company. However, if you are creating the AI model for personal use or it is not associated with any specific company, you can leave this field blank.',
    link:'https://support.younet.ai/article/30-What-would-the-Company-field-in-the-creation-of-an-AI-model-do', charactersLimit: SMALL_FIELD_LIMIT},
    {name: "website", label: "Website", placeholder: "example.com", type: "text", gridProps: {item: true, xs: 12}
    ,tooltip:'The "Website" field in the creation of an AI model is an optional field where you can provide the website associated with your AI model. This field allows you to link your AI model to a specific website if applicable. It can be useful if your AI model is designed to provide information or interact with users on a particular website. However, if your AI model is not associated with any website or you don‘t have a specific website to link it to, you can leave this field blank.',
    link:'https://support.younet.ai/article/31-What-would-the-Website-field-in-the-creation-of-an-AI-model-do', charactersLimit: SMALL_FIELD_LIMIT},
  ];

  const [formFields, setFormFields] = useState(formFieldsInitial);
  const hints = useSelector(state => state.models?.hints?.list?.current);
  const modelsList = useSelector(state => state.models?.list);
  const [getRef, setRef] =  useDynamicRefs();
  const supportedFormats = [
    {
      formats: ['.png', '.jpg', '.jpeg'],
      title: 'Picture'
    }
  ];

  useEffect(() => {
    if (Object.values(showErrorAnimation).some((element) => element)) {
      setTimeout(() => {
        setShowErrorAnimation(initialInputsState);
      }, 300);
    }
  }, [showErrorAnimation]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    const charactersLimit = formFields.filter(item => item.name === name)[0]?.charactersLimit || BIG_FIELD_LIMIT;
    if (value.length >= charactersLimit) {
      setShowErrorAnimation({ ...initialInputsState, [name]: true });
    }
    if (value.length > charactersLimit) {
      return;
    }
    handleInputChange(event);
    setModelState((prevValue) => ({
      ...prevValue,
      [name]: { ...prevValue[name], value },
    }));
  }

  const handleHintChange = (event) => {
    const { name, value } = event.target;

    const charactersLimit = 1000;
    if (value.length >= charactersLimit) {
      setShowErrorAnimation({ ...initialInputsState, [name]: true });
    }
    if (value.length > charactersLimit) {
      return;
    }
    handleInputChange(event);
    setModelState((prevValue) => ({
      ...prevValue,
      [name]: { ...prevValue[name], value },
    }));
  }

  return (
    <>
      <Grid
        container
        rowSpacing={4}
        item
        xs={12}
        noValidate
        sx={{ margin: "auto" }}
        className="form_fields"
      >
        {formFields?.map(({name, label, placeholder, required, type, gridProps,tooltip,link}) => {
          // const currentValue = animationValue[name];
          const currentValue = modelState[name].value;
          const currentValueLength = currentValue?.length;
          const maximumValue = formFields.filter(item => item.name === name)[0].charactersLimit;
          
          const totalSymbols = `${currentValueLength}/${maximumValue}`
          return (
            <Grid {...gridProps} key={name} px={2} className={styles.wrapper}>
              <CharacterCounterTextField
              // <CustomTextField
                inputRef={setRef(`${name}Ref`)}
                label={label}
                placeholder={placeholder}
                name={name}
                error={!!modelState[name].errMsg}
                // helperText={
                //   <div className={styles.helperTextWrapper}>
                //     <div>{modelState[name].errMsg}</div>
                //     {Boolean(currentValueLength) && (
                //       <div className={classnames(styles.symbols, showErrorAnimation[name] && styles.error)}>{totalSymbols}</div>
                //     )}
                //   </div>}
                // value={animationValue[name] || ''}
                value={modelState[name].value || ''}
                required={required}
                fullWidth
                rows={type === "multiline" ? 4 : 1}
                multiline={type === "multiline"}
                onChange={handleChange}
                InputProps={{
                  inputComponent: (name === 'goals' || name === 'rules') ? TextareaAutosize : null,
                  endAdornment: (
                    <InputAdornment position="end" sx={{height: '100%',position: 'absolute', top: '11px', right: '14px'}}>
                      {isTyping[name] ? (
                        <CircularProgress size={24} />
                      ) : (
                        <GeneralTooltip
                          title={
                            <>
                              {tooltip}&nbsp;
                              {link !== '' ? (
                                <a href={link} style={{ color: '#3976ef', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
                                  Learn More
                                </a>
                              ) : ''
                              }
                            </>
                          }
                        >
                          <HelpOutlineIcon sx={{ color: '#B5B5B5' }} />
                        </GeneralTooltip>
                      )}
                    </InputAdornment>
                  )
                }}
              />
              {/* {Boolean(currentValueLength) && <div className={classnames(styles.symbols, showErrorAnimation[name] && styles.error)}>{totalSymbols}</div>} */}
            </Grid>
          )
        })}
        <Grid item px={2} pb={3} sx={{ width: '100%' }}>
          <Box>
            <Typography>Model Icon</Typography>
            <FileInput
              ref={filesRef}
              fileMaxSize={1}
              supportedFormats={supportedFormats}
              selectedTab={0}
              isShort={false}
              isArray={false}
              onChange={(data) => {
                setFileHandler(data);
              }}
              error={modelState.file.errMsg.length}
              // clearFile={clearFile}
            />
          </Box>
          {Boolean(modelState.file.errMsg.length) && (
            <Typography sx={{ color: '#d32f2f', ml: '14px', mr: '14px', mt: '3px', fontSize: '0.75rem' }}>{modelState.file.errMsg}</Typography>
          )}
        </Grid>
        {Boolean(modelId) && Boolean(hintsData) && (
          <Grid item px={2} sx={{ width: '100%' }}>
            <Accordion sx={{ display: 'block', width: '100%'}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`hints-content`}
              >
                Conversation starters
                &nbsp;
                <GeneralTooltip
                  title="Examples for the user to start the conversation"
                >
                  <HelpOutlineIcon sx={{ color: '#B5B5B5' }} />
                </GeneralTooltip>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'block', width: '100%' }}>
                  {Object.keys(hintsData).map((item, i) => {
                    // const currentValue = animationValue[name];
                    const currentValueLength = getRef(`hint${i}Ref`)?.current?.value.length;
                    const maximumValue = BIG_FIELD_LIMIT;
                    const totalSymbols = `${currentValueLength}/${maximumValue}`;

                    return (
                      <Grid key={item} px={2} className={styles.wrapper} sx={{ display: 'block', marginBottom: '5px' }}>
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: '5px' }}>
                          <CustomTextField
                            inputRef={setRef(`hint${i}Ref`)}
                            name={item}
                            // value={animationValue[name] || ''}
                            value={hintsData[item]}
                            required={false}
                            fullWidth
                            rows={1}
                            helperText={
                              <div className={styles.helperTextWrapper}>
                                <div></div>
                                {Boolean(currentValueLength) && (
                                  <div className={classnames(styles.symbols, showErrorAnimation[item] && styles.error)}>{totalSymbols}</div>
                                )}
                              </div>
                            }
                            onChange={handleChange}
                          />
                          <IconButton
                            onClick={() => {deleteHintHandler(item)}}
                            sx={{ width: '40px', height: '40px' }}
                          >
                            <DeleteIcon color="icons" />
                          </IconButton>
                        </Box>
                      </Grid>
                    )
                  })}
                </Box>
                <Box sx={{ padding: '5px 7px' }}>
                  <Button
                    sx={{
                      fontSize: '0.75rem',
                      width: {xs: '90%', sm: 'auto'},
                    }}
                    // variant="outlined"
                    onClick={addHintHandler}
                  >Add Conversation starter</Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sx={{paddingLeft: '8px', paddingRight: '8px', textAlign: 'right'}}>
        <SaveDataButton
          type="submit"
          disabled={[isTyping.name, isTyping.goals, isTyping.role, isTyping.rules].some(condition => condition)}
        />
      </Grid>
    </>
  )
}

export default CreateModelForm;
