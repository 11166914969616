import {memo, useMemo, useState} from 'react';
import {Box, Collapse, Grid, Link, Typography} from '@mui/material';
import classnames from 'classnames';
import styles from "components/ChatConversation/chat-conversation.module.css";
import {sendUIEvent} from "../../../../events/analytics/google";
import trainingTypes from "../../../../mappings/trainingTypes.json";
import {useNavigate} from "react-router-dom";

const SourceList = memo(({title, data}) => {
    const [expandedItems, setExpandedItems] = useState([]);
    const navigate = useNavigate();

    const onClickHandler = (index) => {
        setExpandedItems([...expandedItems, index]);
    }

    const handleRedirect = (id, data_type) => {
        const typePath = trainingTypes[data_type].path;

        navigate(`${typePath}/${id}`);

    }

    return (
        <Grid item className={styles["text-alignment"]}>
            <Typography variant="sources_title">{title}</Typography>
            <Box sx={{display: 'inline', lineHeight: 1.2, padding: '2px 0 0'}}>
                {data.lastIndex === -1 ? (
                    <Typography variant="infoSources_text_truncated">no</Typography>
                ) : (
                    data.sources.map((item, index) => {
                        const isLongText = item.length > 30;
                        const expanded = expandedItems.includes(index);

                        return (
                            <Link sx={{cursor: 'pointer',}} onClick={() => handleRedirect(item.id, item.data_type)} key={index}>


                                <Typography key={index}
                                            className={classnames(!expanded && isLongText && styles.truncate)}
                                            variant={'infoSources_text'}>
                                    {/* <Typography key={index} variant="infoSources_text"> */}
                                    {item.title}
                                    {index !== data.lastIndex ? ', ' : ''}
                                    {isLongText && !expanded && <div style={{
                                        position: 'absolute',
                                        width: '15px',
                                        height: '15px',
                                        right: 0,
                                        top: 0,
                                        cursor: 'pointer'
                                    }} onClick={() => onClickHandler(index)}/>}
                                </Typography>
                            </Link>
                        )
                    })
                )}
            </Box>
        </Grid>
    )
});


const getSources = (sourceList, defaultValue = ["no"]) => {
    const sources = sourceList?.length ? sourceList : defaultValue;
    const lastIndex = sources.length - 1;
    return {sources, lastIndex};
}

const InfoExpandSources = ({sources = {texts: [], files: [], emails: [], qas: [],}, modelVersion = "Standard"}) => {
    const [showBlock, setShowBlock] = useState(false);

    const files = useMemo(() => {
        return getSources(sources?.files);
    }, [sources?.files]);

    const emails = useMemo(() => {
        return getSources(sources?.emails);
    }, [sources?.emails]);

    const qas = useMemo(() => {
        return getSources(sources?.qas);
    }, [sources?.qas]);

    const texts = useMemo(() => {
        return getSources(sources?.texts);
    }, [sources?.texts]);

    const links = useMemo(() => {
        return getSources(sources?.links);
    }, [sources?.links]);


    const handleExpand = () => {
        setShowBlock((prev) => !prev);
        sendUIEvent({name: `last_response_sources_chat_expand_click`});
    }

    const handleExpandEnd = (e) => {
        if (e) {
            e.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }

    return (
        <>
            <Box className={styles["info-expand-container"]}>
                <Box onClick={handleExpand} className={styles["expand-btn"]}>
                    <span>...</span>
                </Box>
            </Box>
            <Collapse className={styles["collapse-sources-container"]} in={showBlock} onEntered={handleExpandEnd}>
                <Grid className={styles["sources-structure"]}>
                    <Grid item padding="0 0 10px">
                        <Typography variant="describe_text" className={styles["description-text"]}>This response
                            generated based on the following sources:</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="sources_title">Neural Network: </Typography> <Typography
                        variant="span"> {modelVersion}</Typography>
                    </Grid>
                    <SourceList title="Text:" data={texts}/>
                    <SourceList title="Email:" data={emails}/>
                    <SourceList title="Files:" data={files}/>
                    <SourceList title="Q&A:" data={qas}/>
                    <SourceList title="Links:" data={links}/>
                </Grid>
            </Collapse>

        </>
    )

}


export default memo(InfoExpandSources);