import { Avatar, Grid, Skeleton, Typography } from "@mui/material";
import { isValidUrl } from "../../../utils/prepareLink";

const MessageStructureSkeleton = ({ loading = false, styles, modelInfo = {} }) => {


    if (!loading) {
        return;
    }

    return (
        <Grid container className={styles["container"]}>
            <Grid
                container
                sx={{
                    padding: { md: "20px 50px 20px 60px", xs: "20px 0" },
                }}
                msg_id={0}
                className={styles["msg-box"] + " " + styles["user"]}
            >
                <Grid item xs={1} sx={{ display: "flex", justifyContent: "end" }}>
                    <Avatar className={styles["avatar"]}></Avatar>
                </Grid>
                <Grid item xs={10} md={11}>
                    <Typography
                        px={{ xs: 3, md: 5 }}
                        className={styles["inner-msg"]}
                        variant="messages"
                    >
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ width: "100%" }}
                        />
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    padding: { md: "20px 50px 20px 60px", xs: "20px 0" },
                }}
                msg_id={1}
                className={styles["msg-box"] + " " + styles["ai"]}
            >
                <Grid item xs={1} sx={{ display: "flex", justifyContent: "end" }}>
                    <Avatar src={isValidUrl(modelInfo.avatar) ? modelInfo.avatar : null} className={styles["avatar"]}>{modelInfo.modelName}</Avatar>
                </Grid>
                <Grid item xs={10} md={11}>
                    <Typography
                        px={{ xs: 3, md: 5 }}
                        className={styles["inner-msg"]}
                        variant="messages"
                    >
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ width: "100%" }}
                        />
                    </Typography>
                </Grid>
            </Grid>
            {/* <Grid
                container
                sx={{
                    padding: { md: "20px 50px 20px 60px", xs: "20px 0" },
                }}
                msg_id={2}
                className={styles["msg-box"] + " " + styles["user"]}
            >
                <Grid item xs={1} sx={{ display: "flex", justifyContent: "end" }}>
                    <Avatar className={styles["avatar"]}></Avatar>
                </Grid>
                <Grid item xs={10} md={11}>
                    <Typography
                        px={{ xs: 3, md: 5 }}
                        className={styles["inner-msg"]}
                        variant="messages"
                    >
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ width: "100%" }}
                        />
                    </Typography>
                </Grid>
            </Grid> */}
            {/* <Grid
                container
                sx={{
                    padding: { md: "20px 50px 20px 60px", xs: "20px 0" },
                }}
                msg_id={3}
                className={styles["msg-box"] + " " + styles["ai"]}
            >
                <Grid item xs={1} sx={{ display: "flex", justifyContent: "end" }}>
                    <Avatar className={styles["avatar"]}>{modelInfo.modelName}</Avatar>
                </Grid>
                <Grid item xs={10} md={11}>
                    <Typography
                        px={{ xs: 3, md: 5 }}
                        className={styles["inner-msg"]}
                        variant="messages"
                    >
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ width: "100%" }}
                        />
                    </Typography>
                </Grid>
            </Grid> */}
        </Grid>
    )
}


export default MessageStructureSkeleton;