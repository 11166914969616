import { useState } from 'react';
import api from "_services/api";
import controlledAPI from "../_services/controlledApi";

const useGetAllBrainFiles = () => {
  const [isGettingAllBrainFiles, setIsGettingAllBrainFiles] = useState(false);

  const handleGetAllBrainFiles = (modelId, successCallback = false) => {
    setIsGettingAllBrainFiles(true);
    controlledAPI({ignoreUser: true})
      .get(`/learning/file/list/${modelId}`)
      .then((res) => {
        setIsGettingAllBrainFiles(false);
        if (!res?.file_memory_items || res.file_memory_items.length === 0) {
          if (successCallback) successCallback([]);
          return;
        }
        if (successCallback) successCallback(res.file_memory_items);
      })
      .catch(() => {
        setIsGettingAllBrainFiles(false);
      });
  };

  return { handleGetAllBrainFiles, isGettingAllBrainFiles };
};

export default useGetAllBrainFiles;
