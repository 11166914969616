import React from "react";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {solarizedlight} from "react-syntax-highlighter/dist/cjs/styles/prism";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Button} from "@mui/material";

const CodeBlock = ({language, value}) => {
    return (
        <React.Fragment>
            <SyntaxHighlighter language={language} style={solarizedlight} wrapLongLines={true}>
                {value}
            </SyntaxHighlighter>
            <CopyToClipboard text={value}>
                <Button variant="contained" color="primary">
                    Copy to Clipboard
                </Button>
            </CopyToClipboard>
        </React.Fragment>
    );
};

export default CodeBlock;