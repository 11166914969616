import {memo, useEffect, useRef, useState} from "react";
import {Box, Grid} from "@mui/material";
import {Divider, Typography, Button} from "@mui/material";
import styles from "./chat-conversation.module.css";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import Message from "./components/Message";
import CachedIcon from "@mui/icons-material/Cached";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import {useMemo} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {deleteLastMsgs, loadMore} from "redux/messages";
import MarkdownComponent from "components/MarkdownComponent";
import {interactWithUser} from "redux/status";
import AccessChecker from "components/security/AccessChecker";
import PersonalizationHints from "pages/protected/tools/Playground/components/PersonalizationHints";
import InfoExpandSources from "./components/InfoExpandSources";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AttachmentLinks from "components/showcase/AttachmentsLinks";
import ScrollDown from "components/userInteractions/ScrollDown";
import {getInitials} from "utils/prepareNames";
import {isValidUrl} from "utils/prepareLink";
import MessageStructureSkeleton from "components/loadings/templates/MessageStructureSkeleton";
import ToolCallsLoadings from "../loadings/templates/ToolCallsLoadings";
import FlexWrapper from "../wrappers/FlexWrapper";
import ToolCalls from "./components/ToolCalls";
import {sendUIEvent} from "../../events/analytics/google";

const ChatConversation = ({
                              userInfo = {},
                              modelInfo = {},
                              sendMessage,
                              scrollContainer = null,
                              isLoadMore = false
                          }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const regenRef = useRef(null);
    const [isRegenInputOpen, setOpenRegenInput] = useState(false);
    const [limit, setLimit] = useState(10);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const shouldRegenerate = searchParams.get("regenerateMsg");
    const {chat_id} = useParams();

    const openRegenInput = () => {
        setOpenRegenInput(true);
    };

    const handleClose = () => {
        setOpenRegenInput(false);
    };

    // const {messagesState, cellsAmount, isSharedChatSelected, sharedChats} = useSelector(
    //     (state) => ({
    //         messagesState: state.messages,
    //         cellsAmount: state.tools.brain.cellsAmount,
    //         isSharedChatSelected: state.chats.source !== "default",
    //         sharedChats: state.chats.sharedChats,
    //     }),
    //     shallowEqual
    // );
    //
    // // const messageState = useSelector((state => state.messages));



    const isMsgSending = useSelector((state => state.messages.session.loading.isMsgSending));
    const messagesLoading = useSelector((state => state.messages.loading));

    const responseStream = useSelector((state => state.messages.session.ai));
    const currentMessage = useSelector((state => state.messages.session.user));
    const attachments = useSelector((state => state.messages.session?.attachments), shallowEqual);

    // const messagesState = useSelector((state => state.messages), shallowEqual);
    const messagesList = useSelector((state => state.messages.list), shallowEqual);
    const chats = useSelector(state => state.chats, shallowEqual);
    const { cellsAmount, loading } = useSelector((state =>  state.tools.brain), shallowEqual);
    const chatSource = useSelector((state => state.chats.source));
    const currentModel = useSelector((state => state.models.current));
    const isSharedChatSelected = chatSource !== "default";
    const sharedChats = useSelector((state => state.chats.sharedChats.items), shallowEqual);
    const isMonetizedModel = currentModel?.access?.includes('view');

    const lastResponseSources = useSelector((state => state.messages.lastResponseSources), shallowEqual);

    const messages = useMemo(() => {
        return messagesList;
    }, [messagesList]);

    useEffect(() => {
      setLimit(messagesList.length);
    }, [messagesList]);

    const chatId = messages[0]?.chat_id;
    const sharedChat = sharedChats?.filter(item => item.id === chatId)[0];

    const lastMessage = messages[messages.length - 1];

    const handleRedirect = (id) => {
        navigate(`/training/files/${id}`)
    }

    useEffect(() => {

        if(shouldRegenerate && !messagesLoading && messages.length > 0) {
            manualRegenerate();
            navigate(location.pathname);
        }

    }, [shouldRegenerate, messages,messagesLoading]);


    const manualRegenerate = () => {


        const lastItem = messages[messages.length - 1];
        const beforeLastItem = messages[messages.length - 2];

        if(lastItem.id != shouldRegenerate) {
            return null
        }


        //
        const idsToDelete = [lastItem.id];
        if (lastItem.type !== "prompt") {
            idsToDelete.push(lastItem.id);
        }

        const handleSendMessage = () => {
            const params = {
                attachments: beforeLastItem.attachments,
                regenerate: 1
            }
            dispatch(deleteLastMsgs(idsToDelete.length));
            sendMessage(null, beforeLastItem.message, params);
            // regenRef.current.value = "";
            handleClose();
        };
        //
        handleSendMessage();

    }
    const regenerateResponse = (e) => {
        const lastItem = messages[messages.length - 1];
        const beforeLastItem = messages[messages.length - 2];

        let promptObject = lastItem.type === "prompt" ? lastItem : beforeLastItem;
        let newMessage = promptObject.message;
        newMessage += "\n" + regenRef.current.value;


        if (newMessage.split(" ").length > 1500) {
            dispatch(interactWithUser({
                message: "The message exceeds the maximum word limit of 1500",
                type: "info",
            }));
            return;
        }

        const idsToDelete = [lastItem.id];
        if (lastItem.type !== "prompt") {
            idsToDelete.push(beforeLastItem.id);
        }

        const handleSendMessage = () => {
            const params = {
                attachments: promptObject.attachments,
                regenerate: 1
            }
            dispatch(deleteLastMsgs(idsToDelete.length));
            sendMessage(null, newMessage, params);
            regenRef.current.value = "";
            handleClose();
        };

        handleSendMessage();
        sendUIEvent({name: `regenerate_response_chat_click`});
    };

    if (messagesLoading) {
        return <MessageStructureSkeleton styles={styles} loading={true} modelInfo={modelInfo}/>
    }
    return (
        <Grid container className={styles["container"]}>
            {/* <BackButton/> */}
            {messages.map((msg, index) => (
              <Message
                  key={index}
                  msg={msg}
                  userInfo={userInfo}
                  modelInfo={modelInfo}
                  isSharedChatSelected={isSharedChatSelected}
                  sharedChat={sharedChat}
              />
            ))}

            {currentMessage != false && (
                <Grid
                    container
                    sx={{
                        padding: {md: "20px 50px 20px 60px", xs: "20px 0"},
                        justifyContent: "center",
                    }}
                    msg_id={0}
                    className={styles["msg-box"] + " " + styles["user"]}
                >
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>
                        <Avatar
                            // src={isSharedChatSelected ? sharedChat?.avatar : userInfo.avatar}
                            src={userInfo.avatar}
                            className={styles["avatar"]}
                        >
                            {/* {sharedChat?.fullName?.length ? getInitials(sharedChat.fullName) : userInfo.initials} */}
                            {userInfo.initials ? userInfo.initials : ''}
                        </Avatar>
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        md={9.5}
                        px={{xs: 2, md: 6}}
                        sx={{display: "flex"}}
                    >
                        <Typography className={styles["inner-msg"]} variant="messages">
              <pre
                  style={{
                      fontFamily: "inherit",
                      margin: 0,
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                  }}
              >
                {currentMessage}
              </pre>
                            {attachments?.length > 0 &&
                                <AttachmentLinks content={attachments} onClick={handleRedirect}/>}
                        </Typography>

                    </Grid>


                    <AccessChecker
                        currentPermissions={userInfo.permissions}
                        requiredPermissions={["write"]}
                    >
                        <Grid item xs={1.5} sx={{display: "flex", alignItems: "start"}}>
                            <div></div>
                        </Grid>
                    </AccessChecker>
                </Grid>
            )}

            <>
                {responseStream === ""  ? (
                    <Grid
                        data-testid="response-stream-loading-line"
                        container
                        sx={{
                            padding: {md: "20px 50px 20px 60px", xs: "20px 0"},
                        }}
                        msg_id={0}
                        className={styles["msg-box"] + " " + styles["ai"]}
                    >
                        <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>
                            <Avatar
                                className={styles["avatar"]}
                                sx={{backgroundColor: "rebeccapurple"}}
                                src={isValidUrl(modelInfo.avatar) ? modelInfo.avatar : null}
                            >
                                {modelInfo.modelName}
                            </Avatar>
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            md={9.5}
                            px={{xs: 2, md: 6}}
                            sx={{display: "flex"}}
                        >
                            <Typography className={styles["inner-msg"]} variant="messages">
                                <Skeleton
                                    variant="text"
                                    animation="wave"
                                    sx={{width: "100%"}}
                                />
                            </Typography>
                        </Grid>
                        <AccessChecker
                            currentPermissions={userInfo.permissions}
                            requiredPermissions={["write"]}
                        >
                            <Grid item xs={1.5} sx={{display: "flex", alignItems: "start"}}>
                                <div></div>
                            </Grid>
                        </AccessChecker>
                    </Grid>
                ) : (
                    responseStream !== "" &&
                    responseStream !== false && (
                        <Grid
                            data-testid="response-stream-streaming-line"
                            container
                            sx={{
                                padding: {md: "20px 50px 20px 60px", xs: "20px 0"},
                                justifyContent: "center",
                            }}
                            msg_id={0}
                            className={styles["ai"]}
                        >
                            <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>
                                <Avatar
                                    className={styles["avatar"]}
                                    sx={{backgroundColor: "rebeccapurple"}}
                                    src={isValidUrl(modelInfo.avatar) ? modelInfo.avatar : null} 
                                >
                                    {modelInfo.modelName}
                                </Avatar>
                            </Grid>
                            <Grid
                                item
                                xs={10}
                                md={9.5}
                                px={{xs: 2, md: 6}}
                                sx={{display: "flex"}}
                            >
                                <Typography className={styles["inner-msg"]} variant="messages">
                                    <MarkdownComponent stream={true} source={responseStream}/>
                                </Typography>
                            </Grid>
                            <AccessChecker
                                currentPermissions={userInfo.permissions}
                                requiredPermissions={["write"]}
                            >
                                <Grid
                                    item
                                    xs={1.5}
                                    sx={{display: "flex", alignItems: "start"}}
                                >
                                    <div></div>
                                </Grid>
                            </AccessChecker>
                        </Grid>
                    )
                )}
            </>

            <Grid item xs={11} sm={11} md={12} sx={{margin: 'auto', flex: '1 !important'}}>
                <ToolCalls/>
            </Grid>

            {!isMsgSending && lastResponseSources.messageId != 0 && lastMessage.type == "response" && (
                <AccessChecker currentPermissions={userInfo.permissions} requiredPermissions={["write"]}>
                    <InfoExpandSources
                        sources={lastResponseSources.list}
                        modelVersion={modelInfo.version}
                    />
                </AccessChecker>
            )}

            {!isMsgSending && messages.length > 0 && !isSharedChatSelected && !isMonetizedModel && !loading && (
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}>

                    <Divider sx={{width: "80%", margin: "0 auto"}}>
                        <Button
                            size="small"
                            color="success"
                            variant="text"
                            onClick={openRegenInput}
                            endIcon={<CachedIcon/>}
                        >
                            Regenerate
                        </Button>
                    </Divider>
                </Grid>
            )}


            {!isMsgSending && cellsAmount === 0 && !isSharedChatSelected && !isMonetizedModel && !loading && <PersonalizationHints  modelName={modelInfo.modelFullName}/> }

            <Dialog open={isRegenInputOpen} onClose={handleClose}>
                <DialogTitle>Regenerate Response</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To get more accurate response, please type what you didn`t like
                        about last response.
                    </DialogContentText>
                    <TextField
                        inputRef={regenRef}
                        autoFocus
                        margin="dense"
                        id="resp-changes"
                        label="Modification Message"
                        type="message"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{borderRadius: "5px"}}
                        color="success"
                        // loading={isMsgSending}
                        loadingPosition="end"
                        endIcon={<CachedIcon/>}
                        onClick={regenerateResponse}
                    >
                        Regenerate
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <ScrollDown
              triggerArray={[messages, currentMessage, responseStream]}
              disableOnScrollUp
              isMsgSending={isMsgSending}
              scrollContainer={scrollContainer}
              isLoadMore={isLoadMore}
            />
        </Grid>
    );
}

export default memo(ChatConversation);
