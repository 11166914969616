import {useMemo} from 'react';
import {useSelector} from "react-redux";

const fullCodeComment = '<!-- Add this block to the <head> or within the <body> at the desired location. -->';

const useScriptCode = ({previewData, }) => {

    const modelHash = useSelector(state => state.models.current.public_hash);
    const modelIcon = useSelector(state => state.models.current.avatar);

    const {hash, type, isBtnNeeded} = useMemo(() => {
        const hash = modelHash || '';
        const type = previewData.type || 'bubble';
        const isBtnNeeded = type !== 'bubble';

        return {hash, type, isBtnNeeded};
    }, [modelHash, previewData.type]);


    const scriptCode = useMemo(() => {
        let paramUrl = `https://widget.younet.ai/index.js?modelHash=${hash}&type=${type}`;
        const btnImg = previewData.btnImg ? encodeURIComponent(previewData.btnImg) : '';
        // Radio button value for the type of the image Younet/Model/Custom Icon
        const iconOption = previewData.iconOption || 'Younet';

        if (iconOption === 'Custom Icon') {
            paramUrl += `&imgUrl=${btnImg}`;
        }else if(iconOption === 'Model' && modelIcon !== null && modelIcon !== ''){
            paramUrl += `&imgUrl=${encodeURIComponent(modelIcon)}`;
        }

        if (previewData.width) {
            paramUrl += `&width=${previewData.width}`;
        }

        if (previewData.height) {
            paramUrl += `&height=${previewData.height}`;
        }

        if (previewData.right) {
            paramUrl += `&right=${previewData.right}`;
        }

        if (previewData.bottom) {
            paramUrl += `&bottom=${previewData.bottom}`;
        }

        if (previewData.language) {
            paramUrl += `&language=${previewData.language}`;
        }

        // pingColor and closeBtnBg are not defined in the snippet
        if (previewData.pingColor) {
            paramUrl += `&pingColor=${previewData.pingColor}`;
        }

        if (previewData.closeBtnBg) {
            paramUrl += `&closeBtnBg=${previewData.closeBtnBg}`;
        }

        return `${fullCodeComment}\n<script \n\ttype="module" \n\tsrc="${paramUrl}">\n</script>`;
    }, [hash, type, isBtnNeeded, previewData.width, previewData.height, previewData.right, previewData.bottom, previewData.language, previewData.btnImg, previewData.iconOption, modelIcon, previewData.pingColor, previewData.closeBtnBg]);

    return scriptCode;
};

export default useScriptCode;

