import { useState, memo } from "react";
import Grid from "@mui/material/Grid";
import {  Popover, Button, TextField, ToggleButtonGroup, ToggleButton, Tooltip, FormControlLabel, Radio, FormLabel } from "@mui/material";
import { SketchPicker } from 'react-color';

export const BubbleSettings = memo(({ handleChanges = () => {} }) => {

    const [iconOption, setIconOption] = useState('Younet');
    const [pingColor, setPingColor] = useState('rgb(57, 118, 239)');
    const [closeBtnBg, setCloseBtnBg] = useState('linear-gradient(135deg, rgb(180, 90, 252) 15%, rgb(66, 133, 255) 50%, rgb(0, 178, 248) 80%)');

    const [anchorEl, setAnchorEl] = useState(null);
    const isPopoverOpen = Boolean(anchorEl);
    const handleCloseBtnClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [pingColorAnchorEl, setPingColorAnchorEl] = useState(null);

    const isPingColorPopoverOpen = Boolean(pingColorAnchorEl);

    const handlePingColorBtnClick = (event) => {
        setPingColorAnchorEl(event.currentTarget);
    };

    const handlePingColorClose = () => {
        setPingColorAnchorEl(null);
    };

    const handleOptionChange = (event, newOption) => {
        if (newOption !== null) {
            setIconOption(newOption);
            handleChanges({ iconOption: newOption });
        }
    };

    const onImgChange = (e) => {
        const value = e.target.value;
        handleChanges({ btnImg: value });
    };

    const handlePingColorChange = (color) => {
        const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        setPingColor(rgbaColor);
        handleChanges({ pingColor: rgbaColor });
    };

    const handleCloseBtnBgChange = (color) => {
        const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        setCloseBtnBg(rgbaColor);
        handleChanges({ closeBtnBg: rgbaColor });
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
            <FormLabel component="legend">Bubble Icon Image</FormLabel>
                <ToggleButtonGroup
                    fullWidth
                    color="primary"
                    value={iconOption}
                    exclusive
                    onChange={handleOptionChange}
                    aria-label="Bubble Icon Image"
                >
                    <Tooltip value="Younet" title="Use the default Younet icon for the bubble.">
                        <ToggleButton value="Younet">Younet</ToggleButton>
                    </Tooltip>
                    <Tooltip value="Model" title="Use the saved model icon.">
                        <ToggleButton value="Model">Model</ToggleButton>
                    </Tooltip>
                    <Tooltip value="Custom Icon" title="Use a custom icon that is hosted on the public url.">
                    <ToggleButton value="Custom Icon">Custom Icon</ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
            </Grid>
            {iconOption === 'Custom Icon' && (
                <Grid item xs={12}>
                    <TextField
                        variant="filled"
                        placeholder="Add your icon URL for the bubble (e.g., https://younet.ai/icon)"
                        sx={{ opacity: 0.6 }}
                        defaultValue=""
                        fullWidth
                        label="Bubble Image"
                        onChange={onImgChange}
                        InputProps={{
                            style: { paddingRight: "5px" },
                        }}
                    />
                </Grid>
            )}
            <Grid item xs={12} lg={12} md={12}>
                <FormLabel component="legend">Pick Ping Color</FormLabel>
                <Button
                    variant="outlined"
                    onClick={handlePingColorBtnClick}
                    sx={{
                    width: '100%',
                    height: '36px',
                    backgroundColor: pingColor,
                    textTransform: 'none',
                    }}
                />
                <Popover
                    open={isPingColorPopoverOpen}
                    anchorEl={pingColorAnchorEl}
                    onClose={handlePingColorClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <SketchPicker
                    color={pingColor}
                    onChangeComplete={handlePingColorChange}
                    />
                </Popover>
                </Grid>
            <Grid item xs={12} lg={12} md={12}>
                <FormLabel component="legend">Pick Close Button Background</FormLabel>
                <Button
                    variant="outlined"
                    onClick={handleCloseBtnClick}
                    sx={{
                        width: '100%',
                        height: '36px',
                        backgroundColor: closeBtnBg,
                        textTransform: 'none'
                    }}
                ></Button>
                <Popover
                    open={isPopoverOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <SketchPicker
                        color={closeBtnBg}
                        onChangeComplete={handleCloseBtnBgChange}
                    />
                </Popover>
            </Grid>
        </Grid>
    );
});